import { passwordREGEX } from "@remar/shared/dist/utils/serviceUtils";

import { validateZipCode } from "@remar/shared/dist/utils/validateZipCode";
import * as Yup from "yup";

export const passwordValidationMessage =
	"The password must contain at least 6 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.";
const PasswordMatch = Yup => Yup.string().matches(passwordREGEX, passwordValidationMessage);

export const SignupSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	password: PasswordMatch(Yup).required("Required"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Required"),
	terms: Yup.bool()
	// we might nedd it in future
	// terms: Yup.bool().oneOf([true], "You should accept these terms to sign up")
});
export const payedSignupSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	password: PasswordMatch(Yup).required("Required"),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Required")
});

export const SocialTrialSignupSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	terms: Yup.bool()
});

export const ShippingSchema = Yup.object().shape({
	countryId: Yup.number().required("Required"),
	address1: Yup.string().required("Required"),
	city: Yup.string().min(2).required("Required"),
	state: Yup.string().min(2).required("Required"),
	zip: validateZipCode(Yup).max(10).required("Required"),
	phoneNumber: Yup.string()
		.max(20, "Phone number must be at most 20 characters")
		.min(7, "Phone number must be at least 7 characters")
		.required("Required")
		.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
});

export const PaymentSchema = Yup.object().shape({
	nameOnCard: Yup.string().required("Required"),
	validCardDetails: Yup.bool().required("Required").equals([true]),
	terms: Yup.bool().oneOf([true], "You should accept these terms to sign up")
});

export const GuestShippingSchema = Yup.object().shape({
	firstName: Yup.string().min(1, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(1, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
	countryId: Yup.number().required("Required"),
	address1: Yup.string().required("Required"),
	city: Yup.string().min(2).required("Required"),
	state: Yup.string().min(2).required("Required"),
	zipCode: validateZipCode(Yup).max(10).required("Required"),
	phoneNumber: Yup.string()
		.max(20, "Phone number must be at most 20 characters")
		.min(7, "Phone number must be at least 7 characters")
		.required("Required")
		.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
});

export const AccountSchema = Yup.object().shape({
	firstName: Yup.string().required("required"),
	lastName: Yup.string().required("required"),
	email: Yup.string().email("Invalid email").required("Required"),
	password: Yup.string().matches(passwordREGEX, " ").required("Required")
});
