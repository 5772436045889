import React from "react";

import { Box, Typography } from "@material-ui/core";

import { ReactComponent as IconHospitalUser } from "@remar/shared/dist/assets/icons/Icon-hospital-user.svg";

import { CourseContainer, CourseWrapper } from "./styles";

const SelectCourse = ({ courses, setPickCourse }) => (
	<CourseContainer>
		{courses.map(({ id, name, description, mainImageUrl }) => (
			<CourseWrapper key={id} onClick={() => setPickCourse(id)}>
				{mainImageUrl ? <img src={mainImageUrl} alt="Course image" /> : <IconHospitalUser />}
				<Box className={"infoWrapper"}>
					<Typography>{name}</Typography>
					<Typography>{description}</Typography>
				</Box>
			</CourseWrapper>
		))}
	</CourseContainer>
);

export default SelectCourse;
