import { Color } from "@material-ui/lab";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NotificationDeliveryMethodEnum } from "@remar/shared/dist/models/notifications.model";

import { AppThunk, RootState } from "store";

import {
	IBannerData,
	ISocketNotificationItem,
	ISocketNotifications,
	NotificationItem,
	NotificationsState
} from "./notifications.model";

import { usersService } from "../../services";

const defaultDelay = 3000;

const initialState: NotificationsState = {
	items: [],
	bellNotifications: { items: [] as ISocketNotificationItem[] } as ISocketNotifications,
	bannerNotifications: { items: [] as ISocketNotificationItem[] } as ISocketNotifications,
	loadingBellNotifications: false,
	loadingBannerNotifications: false,
	bannerData: null
};
export const pushSocketNotification = createAsyncThunk(
	"notifications/pushSocketNotification",
	async (data: ISocketNotificationItem, { dispatch }) => {
		dispatch(pushBellNotifications(data));
	}
);

export const getUserNotifications = createAsyncThunk(
	"notifications/getUserNotifications",
	async (data: { filters: Record<string, string> }, { rejectWithValue }) => {
		return await usersService.getUserNotifications(data).catch(e => rejectWithValue(e.message));
	}
);
export const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		fireEvent: (state, action: PayloadAction<NotificationItem>) => {
			state.items = [...state.items, action.payload];
		},
		dismiss: (state, action: PayloadAction<number>) => {
			state.items = state.items.filter(item => item.id !== action.payload);
		},
		dismissAll: state => {
			state.items = [];
		},
		pop: state => {
			const newState = state.items.slice();
			newState.shift();
			state.items = newState;
		},
		pushBellNotifications: (state, { payload }: PayloadAction<ISocketNotificationItem>) => {
			const {
				notification: { deliveryMethod }
			} = payload;
			if (deliveryMethod === NotificationDeliveryMethodEnum.Banner) {
				state.bannerNotifications.items = [...state.bannerNotifications.items, payload];
			} else {
				state.bellNotifications.items = [...state.bellNotifications.items, payload];
			}
		},
		setBannerData: (state, { payload }: PayloadAction<IBannerData>) => {
			state.bannerData = payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUserNotifications.pending, (state, { meta: { arg } }) => {
				const { filters } = arg;
				const type = filters["notification.deliveryMethod"];
				if (type === NotificationDeliveryMethodEnum.Banner) {
					state.loadingBannerNotifications = true;
				} else {
					state.loadingBellNotifications = true;
				}
			})
			.addCase(getUserNotifications.fulfilled, (state, { payload, meta: { arg } }) => {
				const { filters } = arg;
				const type = filters["notification.deliveryMethod"];
				if (type === NotificationDeliveryMethodEnum.Banner) {
					state.loadingBannerNotifications = true;
					state.bannerNotifications = payload;
				} else {
					state.bellNotifications = payload;
					state.loadingBellNotifications = true;
				}
			})
			.addCase(getUserNotifications.rejected, (state, { meta: { arg } }) => {
				const { filters } = arg;
				const type = filters["notification.deliveryMethod"];
				if (type === NotificationDeliveryMethodEnum.Banner) {
					state.loadingBannerNotifications = false;
				} else {
					state.loadingBellNotifications = false;
				}
			});
	}
});

export const { fireEvent, dismiss, pop, dismissAll, pushBellNotifications, setBannerData } = notificationsSlice.actions;

// DEPRECATED version, for thunks we need to pass only one argument
export const _emit =
	(message: string, color: Color, preventAutoDismiss = false): AppThunk =>
	(dispatch, getState) => {
		const state = getState();
		const lastItem = state.notifications.items[state.notifications.items.length - 1];
		const lastId = lastItem?.id + 1 || 1;
		dispatch(fireEvent({ message, type: color, id: lastId }));
		if (!preventAutoDismiss) {
			setTimeout(() => {
				dispatch(dismiss(lastId));
			}, defaultDelay);
		}
	};

export const emit = createAsyncThunk(
	"notifications/emit",
	(
		{
			message = "Unknown error",
			color,
			preventAutoDismiss = false
		}: {
			message: string;
			color: Color;
			preventAutoDismiss?: boolean;
		},
		{ dispatch, getState }
	) => {
		const state = getState() as RootState;
		const lastItem = state.notifications.items[state.notifications.items.length - 1];
		const lastId = lastItem?.id + 1 || 1;
		dispatch(fireEvent({ message, type: color, id: lastId }));
		if (!preventAutoDismiss) {
			setTimeout(() => dispatch(dismiss(lastId)), defaultDelay);
		}
	}
);

export const selectNotifications = ({ notifications }: RootState): NotificationItem[] => notifications.items;
export const getFullState = (state: RootState): NotificationsState => state.notifications;
export default notificationsSlice.reducer;
