import React from "react";

import { BaseLayout as SharedLayout } from "@remar/shared/dist/layouts/BaseLayout";
import { RootState, useAppSelector } from "store";
import { getFullState } from "store/features/notifications/notifications.slice";

import SideNav from "./SideNav";
import { TopNav } from "./TopNav";

const BaseLayout: React.FC = ({ children }) => {
	const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);

	const { bannerData } = useAppSelector(getFullState);
	return (
		<SharedLayout isLoggedIn={isLoggedIn} topNav={<TopNav />} sideBarNav={<SideNav />} isTrial={bannerData?.showBanner}>
			{children}
		</SharedLayout>
	);
};

export default BaseLayout;
