import React from "react";

import { Box, Card, LinearProgress, Typography } from "@material-ui/core";

import IconConfetti from "../../../assets/icons/Confetti.svg";

import IconCertificate from "../../../assets/icons/icon-certificate.svg";

import CheckMarkSVG from "../../../assets/icons/icon-checkmark.svg";

import { usePDFThumbnail } from "../../../hooks/usePDFThumbnail";
import ContentLoader from "../../../layouts/TableContentLayout/components/ContentLoader";
import { ICourseCompletion } from "../../../models";

import { downloadExternalFile } from "../../../utils/serviceUtils";

import Button from "../../Button";
import {
	Certificate,
	CourseCompletionBody,
	CourseCompletionHeader,
	CourseProgress,
	NextCourse,
	StyledConfetti,
	StyledIconCertificate
} from "../styles";

interface ICourseCompletionProps {
	courseCompletion: ICourseCompletion;
	nextLesson: any;
	courseName: string;
	seeDetails: () => void;
	onContinueNextLesson?: () => void;
}
const CourseCompletionSummary = ({
	courseCompletion,
	nextLesson,
	seeDetails,
	courseName,
	onContinueNextLesson
}: ICourseCompletionProps) => {
	const { isCompleted, percentage, certificatePdfUrl } = courseCompletion;
	const { thumbnail: pdfThumbnail, loading: loadingPdfThumbnail } = usePDFThumbnail({
		pdfUrl: certificatePdfUrl as string
	});
	const { lesson, sectionMainThumbnailUrl, sectionMainImageUrl } = nextLesson || {};
	const isStudent = !!onContinueNextLesson;
	return (
		<Card style={{ flex: 2, height: "100%" }}>
			<CourseCompletionHeader>
				<Typography>Course Completion</Typography>
				<Typography onClick={seeDetails}>See Details</Typography>
			</CourseCompletionHeader>
			<CourseCompletionBody>
				<CourseProgress>
					{isCompleted && <StyledConfetti src={IconConfetti} alt="StyledConfetti" />}
					<Typography className={"course-progress-label"}>Course</Typography>
					<Typography className={"course-name"}>{courseName}</Typography>
					<Typography className={"course-progress-label"}>{isStudent ? "My" : "Student"} Progress</Typography>
					<Typography className={"course-progress-value"}>{Math.round(percentage)}% completed</Typography>
					<LinearProgress className={"progress"} variant="determinate" value={percentage} />
				</CourseProgress>

				{isCompleted ? (
					<NextCourse courseCompleted>
						<img style={{ marginBottom: "20px" }} src={CheckMarkSVG} alt="CheckMarkSVG" />
						<Typography className={"unlock-feature"}>New feature unlocked</Typography>
						<Typography className={"retake-lesson"}>
							{isStudent ? "You" : "Student"} can now go back and retake lesson tests
						</Typography>
					</NextCourse>
				) : (
					<NextCourse style={{ minWidth: "250px" }}>
						<Typography className={"next-course-label"}>Next</Typography>
						<Typography className={"course-name"}>{lesson?.name}</Typography>
						<img
							className={"lesson-thumbnail"}
							src={
								(lesson?.mainImageKey
									? lesson?.mainImageThumbnailUrl?.small250 || lesson?.mainImageUrl
									: sectionMainThumbnailUrl?.small250 || sectionMainImageUrl) || "https://picsum.photos/200"
							}
							alt={"Course Thumbnail"}
						/>
						{onContinueNextLesson && (
							<Button variant={"outlined"} color={"basic"} fullWidth onClick={onContinueNextLesson}>
								Continue Course
							</Button>
						)}
					</NextCourse>
				)}
				<Certificate>
					{loadingPdfThumbnail ? (
						<ContentLoader height={100} size={"2rem"} />
					) : isCompleted ? (
						<>
							<Typography>{isStudent ? "Your" : "Student"} Certificate</Typography>
							{pdfThumbnail ? (
								<Box my={1}>
									<img width={140} height={160} src={pdfThumbnail} alt="PDF Thumbnail" />
								</Box>
							) : (
								<StyledIconCertificate src={IconCertificate} alt={"certificate"} />
							)}
							<Button
								variant="filled"
								color="primary"
								onClick={() => downloadExternalFile(courseCompletion.certificatePdfUrl as string)}
							>
								Download Certificate
							</Button>
						</>
					) : (
						<>
							<StyledIconCertificate src={IconCertificate} alt={"certificate"} />
							<Typography>No certificate yet</Typography>
						</>
					)}
				</Certificate>
			</CourseCompletionBody>
		</Card>
	);
};

export default CourseCompletionSummary;
