import React from "react";

import { Box } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import {
	CourseChapterSectionLesson,
	Question,
	UserLessonAttempt,
	UserQuestionAttempt
} from "@remar/shared/dist/models";
import { UserQuestionAnswerDto } from "@remar/shared/dist/services/userQuestionAnswers";
import useAnalyticsEventTracker from "hooks/googleAnalytics";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState, retakeTest, startUserActivityTracking } from "store/features/Lesson/lesson.slice";
import { ACTIVITY_TYPE } from "store/services";

import { routes } from "core/constants";

import {
	CorrectAnswersIcon,
	IncorrectAnswersIcon,
	QuizPercentage,
	QuizResult,
	QuizResultButtons,
	QuizResultHeader,
	QuizResultSummary,
	QuizResultSummaryRow,
	QuizResultTitle
} from "./style";

type QuizResultInfoProps = {
	lessonId: number;
	sectionLessonId: number;
	quizResult: UserQuestionAttempt[] | undefined;
	setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
	setResetCountdown: React.Dispatch<React.SetStateAction<boolean>>;
	setShowCompletionCertificate: React.Dispatch<React.SetStateAction<boolean>>;
	isCourseComplete: boolean;
	isTrial: boolean | undefined;
	singleQAnswerRef: React.MutableRefObject<{}>;
	csRef: React.MutableRefObject<{}>;
	lessonAttempt: UserLessonAttempt | undefined;
	nextLesson: CourseChapterSectionLesson;
	handleNextLesson: () => void;
	setQuestion: React.Dispatch<React.SetStateAction<Question | null>>;
	setTypeId: React.Dispatch<React.SetStateAction<number | null>>;
	setQuestionId: React.Dispatch<React.SetStateAction<number>>;
	setQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
	setUserAnswers: React.Dispatch<React.SetStateAction<UserQuestionAnswerDto[]>>;
};
const QuizResultInfo = ({
	lessonId,
	sectionLessonId,
	nextLesson,
	quizResult,
	setShowResults,
	isCourseComplete,
	isTrial,
	singleQAnswerRef,
	csRef,
	// lessonAttempt,
	setResetCountdown,
	setShowCompletionCertificate,
	handleNextLesson,
	setQuestionIndex,
	setUserAnswers,
	setQuestion,
	setQuestionId,
	setTypeId
}: QuizResultInfoProps) => {
	const dispatch = useAppDispatch();
	const analytics = useAnalyticsEventTracker("Lesson");
	const history = useHistory();
	const resetRefIds = (ids, currentRef) => {
		ids.forEach(questionId => {
			currentRef.current[questionId] = [];
		});
	};

	const { quizPassed, quizPercentage } = useAppSelector(getFullState);
	const handleRetest = () => {
		setResetCountdown(c => !c);
		dispatch(retakeTest());
		resetUserAnswers();
		dispatch(startUserActivityTracking({ activityType: ACTIVITY_TYPE.testAttempt, id: +lessonId }));
	};

	const resetUserAnswers = () => {
		const questionIds = Object.keys(singleQAnswerRef?.current || []);
		const csQuestionIds = Object.keys(csRef?.current || []);
		setQuestion(null);
		setTypeId(0);
		setQuestionId(0);
		setQuestionIndex(0);
		setUserAnswers([]);
		resetRefIds(questionIds, singleQAnswerRef);
		resetRefIds(csQuestionIds, csRef);
	};

	return (
		<QuizResult>
			<QuizResultHeader>{quizPassed ? "Test passed" : "Test failed"}</QuizResultHeader>
			<QuizResultTitle>
				{quizPassed ? (
					<>
						Congrats!
						<br />
						You have Passed the test
					</>
				) : (
					<>
						Try Again!
						<br />
						You have Failed the test
					</>
				)}
			</QuizResultTitle>
			<QuizResultSummary>
				<QuizPercentage passed={quizPassed}>
					<div>Your score:</div>
					{quizPercentage}%
				</QuizPercentage>
				<div>
					<QuizResultSummaryRow>
						<CorrectAnswersIcon /> {quizResult.filter(({ isCorrect }) => isCorrect).length} questions correct
					</QuizResultSummaryRow>
					<QuizResultSummaryRow>
						<IncorrectAnswersIcon /> {quizResult.filter(({ isCorrect }) => !isCorrect).length} questions incorrect
					</QuizResultSummaryRow>
				</div>
			</QuizResultSummary>
			<QuizResultButtons>
				{quizPassed && (
					<Box display="flex" flexDirection="row" justifyContent="center" gridGap={16}>
						<Button
							variant="filled"
							color="secondary"
							onClick={() => {
								setShowResults(true);
								analytics({ eventName: "check_results_after_test", eventIdentifier: `${sectionLessonId}` });
							}}
						>
							View results
						</Button>{" "}
						<Button
							variant="filled"
							color="secondary"
							onClick={() => history.push(`${routes.lesson.getPath()}/${lessonId}/${sectionLessonId}/history`)}
						>
							See Test History
						</Button>
						{isCourseComplete && quizPassed && !isTrial && (
							<>
								<Button variant="filled" color="primary" onClick={() => setShowCompletionCertificate(true)}>
									View Completion Certificate
								</Button>
								{
									/*!lessonAttempt?.practiceAttempt &&*/
									<Button variant="filled" color="primary" onClick={handleRetest}>
										Retake the test
									</Button>
								}
							</>
						)}
						{nextLesson && (
							<Button
								variant="filled"
								color="primary"
								onClick={() => {
									handleNextLesson();
									analytics({
										eventName: "click_next_lesson_from_results",
										eventIdentifier: `${nextLesson.id}`
									});
								}}
							>
								Next lesson
							</Button>
						)}
					</Box>
				)}
				{!quizPassed && (
					<Button
						variant="filled"
						color="primary"
						onClick={() => {
							analytics({
								eventName: "retake_test_after_failiure",
								eventIdentifier: `${lessonId}`
							});
							handleRetest();
						}}
					>
						Retake test
					</Button>
				)}
			</QuizResultButtons>
		</QuizResult>
	);
};

export default QuizResultInfo;
