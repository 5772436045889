interface IConvertedTime {
	remainingDays: number;
	remainingHours: number;
	remainingMinutes: number;
	remainingSeconds: number;
}
const convertSecToRemainingTime = (seconds: number): IConvertedTime => {
	const remainingDays = Math.floor(seconds / (24 * 60 * 60));
	const remainingHoursInSeconds = seconds % (24 * 60 * 60);
	const remainingHours = Math.floor(remainingHoursInSeconds / (60 * 60));
	const remainderMinutesInSeconds = remainingHoursInSeconds % (60 * 60);
	const remainingMinutes = Math.floor(remainderMinutesInSeconds / 60);
	const remainingSeconds = remainderMinutesInSeconds % 60;

	return { remainingDays, remainingHours, remainingMinutes, remainingSeconds };
};

export default convertSecToRemainingTime;
