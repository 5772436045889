import React from "react";

import { Box, Typography } from "@material-ui/core";

import styled from "styled-components";

import CheckMarkActive from "../../assets/icons/icon-checkmark-filled-active.svg";
import CheckMark from "../../assets/icons/icon-checkmark-filled.svg";

import { IPasswordBoxValidation } from "../../models";

const PasswordMatchWrapper = styled(Box)`
	p {
		font-size: 13px;
		font-family: var(--openSans);
		font-weight: 600;
		color: ${props => props.theme.palette.colors.basic[1200]};
	}
`;

const IconComponent = ({ isValid }) =>
	isValid ? (
		<img src={CheckMarkActive} alt="check" width={"17px"} />
	) : (
		<img src={CheckMark} alt="close" width={"17px"} />
	);

const ValidationWrapper = ({ children }) => (
	<Box display={"flex"} flexDirection="row" alignItems={"center"} mb={2}>
		{children}
	</Box>
);

type PasswordMatchIndicatorProps = { passwordErrorBox: IPasswordBoxValidation };
const PasswordMatchIndicator = ({
	passwordErrorBox: { characters, lowerCase, upperCase, specialCharacter, numbers }
}: PasswordMatchIndicatorProps) => {
	return (
		<PasswordMatchWrapper>
			<Box className={"heading"} mb={2}>
				<Typography>password must contain:</Typography>
			</Box>
			<Box display={"flex"} flexDirection="column">
				<ValidationWrapper>
					<IconComponent isValid={characters} />
					<Box ml={1}>
						<Typography>At least 6 characters</Typography>
					</Box>
				</ValidationWrapper>
				<ValidationWrapper>
					<IconComponent isValid={lowerCase} />
					<Box ml={1}>
						<Typography>At least 1 lower case letter (a…z)</Typography>
					</Box>
				</ValidationWrapper>
				<ValidationWrapper>
					<IconComponent isValid={upperCase} />
					<Box ml={1}>
						<Typography>At least 1 upper case letter (A…Z)</Typography>
					</Box>
				</ValidationWrapper>
				<ValidationWrapper>
					<IconComponent isValid={numbers} />
					<Box ml={1}>
						<Typography>At least 1 number</Typography>
					</Box>
				</ValidationWrapper>
				<ValidationWrapper>
					<IconComponent isValid={specialCharacter} />
					<Box ml={1}>
						<Typography>At least 1 special character (!$@-)</Typography>
					</Box>
				</ValidationWrapper>
			</Box>
		</PasswordMatchWrapper>
	);
};

export default PasswordMatchIndicator;
