import { Accordion, Box, Card, darken } from "@material-ui/core";

import { lighten } from "@material-ui/core/styles";
import styled, { css } from "styled-components";

export const CourseCompletionHeader = styled(Box)`
	display: flex;
	justify-content: space-between;
	p:first-child {
		font-size: 15px;
		font-weight: 600;
	}
	p:last-child {
		font-size: 13px;
		color: ${({ theme }) => theme.palette.colors.primary[1900]};
		font-weight: 700;
		cursor: pointer;
	}
`;

export const CourseCompletionBody = styled(Box)`
	margin-top: 20px;
	display: flex;
	justify-content: flex-start;
	${props => props.theme.breakpoints.down("xs")} {
		flex-direction: column;
	}
`;

export const CourseProgress = styled(Box)`
	background: ${({ theme, detailed }) =>
		detailed ? theme.palette.background.paper : lighten(theme.palette.background.paper, 0.05)};
	padding: 20px;
	flex-grow: 3;
	position: relative;
	${({ detailed }) =>
		detailed &&
		css`
			height: 100%;
		`}

	p {
		margin: 5px 0;
	}
	.course-progress-label {
		font-size: 13px;
		color: ${({ theme }) => theme.palette.colors.basic[1250]};
	}
	.course-name {
		font-size: 15px;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.course-progress-value {
		font-size: 22px;
		font-weight: 700;
	}
	.progress {
		margin-top: 15px;
	}
`;

export const StyledConfetti = styled.img`
	position: absolute;
	top: 0;
	right: 0;
`;

const commonNextCourseStyle = css`
	display: flex;
	flex-direction: column;
	align-items: ${({ courseCompleted }) => (courseCompleted ? "center" : "flex-start")};
	padding: 20px 15px;
	flex-grow: 0;
	${({ detailed }) =>
		detailed &&
		css`
			flex-direction: row;
			justify-content: space-between;
		`}
	p {
		margin: 5px 0;
	}
	.next-course-label {
		font-size: 13px;
		color: ${({ theme }) => theme.palette.colors.basic[1250]};
	}
	.next-course-description {
		font-size: 12px;
		color: ${({ theme }) => theme.palette.colors.basic[1250]};
	}
	.course-name {
		font-size: 15px;
		font-weight: 600;
		margin-bottom: 10px;
	}
	.lesson-thumbnail {
		width: 100%;
		height: 120px;
		margin: 10px 0;
	}
	svg {
		margin: 10px 0;
	}
	.retake-lesson {
		width: 60%;
		text-align: center;
	}
	.detailed {
		width: 50%;
	}
`;
export const NextCourse = styled(Box)`
	background: ${({ theme }) => lighten(theme.palette.background.paper, 0.05)};
	margin: 0 15px;

	${props => props.theme.breakpoints.down("xs")} {
		width: 100%;
		margin: 10px 0;
	}

	${commonNextCourseStyle}
`;

export const DetailedNextCourse = styled(Card)`
	height: 100%;

	${props => props.theme.breakpoints.down("xs")} {
		width: 100%;
	}

	${commonNextCourseStyle}
`;

export const Certificate = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 200px;
`;

export const DownloadCertificate = styled(Box)`
	display: flex;
	${props => props.theme.breakpoints.down("xs")} {
		flex-direction: column;
	}
	.certificate_download {
		flex-basis: 35%;
		p:first-child {
			font-size: 15px;
			margin-bottom: 10px;
		}
		p:nth-child(2) {
			font-size: 13px;
			margin: 15px 0;
		}
	}
	.certificate {
		flex-basis: 30%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.certificate_logs {
		//width: 40%;
		display: flex;
		flex-direction: column;
		flex-basis: 35%;

		p {
			font-family: var(--openSans);
			font-weight: 600;
		}
		.title {
			font-size: 13px;
			margin-bottom: 10px;
		}
		.logsWrapper {
			overflow-y: scroll;
			scroll-behavior: smooth;
			height: 160px;
			.logs {
				margin-bottom: 10px;
				p {
					font-size: 12px;
				}
				p:last-child {
					font-weight: 400;
				}
			}
		}
	}
`;

export const ChipsWrapper = styled(Box)`
	display: flex;
	flex-flow: row wrap;
	gap: 10px;
	align-items: center;
	margin-top: 2px;
	.chip {
		background-color: ${({ theme }) => theme.palette.colors.basic[300]};
		color: ${({ theme }) => theme.palette.basic.contrastText};
	}
`;

export const StyledIconCertificate = styled.img`
	width: 45px;
	height: 45px;
	margin: 20px;
`;

export const StyledAccordion = styled(Accordion)`
	margin: 20px 0;
	&:before {
		display: none;
	}
`;
export const LessonProgressAccordion = styled(Box)`
	display: flex;
	flex-direction: column;
`;

export const AccordionSummaryWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	p {
		font-size: 15px;
		font-weight: 600;
		color: ${({ theme }) => darken(theme.palette.text.primary, 0.2)};
	}
`;
export const SectionsWrapper = styled(Box)`
	display: -webkit-box;
	overflow-y: scroll;
`;
export const AccordionLesson = styled(Card)`
	display: flex;
	flex-direction: column;
	width: 220px;
	border-radius: 5px;
	padding: 0;
	background: ${({ theme }) => theme.palette.colors.primary[960]};
	position: relative;
	margin: 5px 10px;
	.lesson-thumbnail {
		width: 100%;
		height: 140px;
	}
	.lesson-name {
		font-size: 13px;
		font-weight: 600;
		margin-bottom: 5px;
	}
	.lesson-description {
		font-size: 12px;
		color: ${({ theme }) => theme.palette.text.secondary};
		margin-bottom: 10px;
	}
`;

export const iconStyle = css`
	position: absolute;
	top: 3px;
	right: 3px;
	border-radius: 3px;
	padding: 3px;
`;
export const StyledLessonLockSvg = styled.img`
	${iconStyle}
`;

export const StyledCrownSvg = styled.img`
	${iconStyle}
`;

export const StyledCheckMarkSVG = styled.img`
	${iconStyle};
	background: unset;
	padding: 0;
`;
