import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Lesson, UserCustomTest, UserQuestionBankStats } from "@remar/shared/dist/models";

import { downloadExternalFile } from "@remar/shared/dist/utils/serviceUtils";
import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { RootState } from "store";

import {
	LessonQuestionStats,
	SubjectQuestionStats,
	questionsService,
	subjectLessonService,
	userCustomTestsService
} from "store/services";

import { emit } from "../notifications/notifications.slice";

export interface Subject {
	id: number;
	name: string;
	lessons: Lesson[];
}

export interface QuestionBankState {
	isLoading: boolean;
	isLoadingTests: boolean;
	isLoadingStats: boolean;
	page: number;
	perPage: number;
	tests: UserCustomTest[];
	totalItems: number;
	errorMessage: string;
	userQuestionBankPerformance: number;
	subjects: Subject[];
	questionBankStats: UserQuestionBankStats;
	subjectQuestionStats: SubjectQuestionStats;
	lessonQuestionStats: LessonQuestionStats;
}

const initialState: QuestionBankState = {
	isLoading: false,
	isLoadingTests: false,
	isLoadingStats: false,
	page: 1,
	perPage: 10,
	totalItems: 0,
	subjects: [],
	tests: [],
	errorMessage: "",
	userQuestionBankPerformance: 0,
	questionBankStats: {
		userQuestionsCount: {
			totalquestions: 0,
			totalquestionAttempts: 0,
			totalRemainingQuestions: 0
		},
		userAnswersCount: {
			correctAnswers: 0,
			incorrectAnswers: 0,
			partialCorrectAnswers: 0
		}
	},
	subjectQuestionStats: {
		remainingQuestionCount: 0,
		totalSubjectCorrectQuestionCount: 0,
		totalSubjectIncorrectQuestionCount: 0,
		totalSubjectPartialCorrectQuestionCount: 0
	},
	lessonQuestionStats: {
		remainingQuestionCount: 0,
		totalLessonCorrectQuestionCount: 0,
		totalLessonIncorrectQuestionCount: 0,
		totalLessonPartialCorrectQuestionCount: 0
	}
};

export const fetchQuestionBankStats = createAsyncThunk(
	"questionBank/fetchQuestionBankStats",
	async (options: {}, { dispatch, getState }) => {
		const { isLoadingStats } = (
			getState() as {
				questionBank: QuestionBankState;
			}
		).questionBank;

		if (!isLoadingStats) {
			dispatch(setLoading(true));
		}
		try {
			const res = await questionsService.getUserQuestionBankStats();
			dispatch(setStateValue({ key: "questionBankStats", value: res }));
			return res;
		} catch (e) {
			return { error: e };
		} finally {
			dispatch(setLoading(false));
		}
	}
);

export const downloadTestResults = createAsyncThunk("questionBank/downloadTest", async (id: number, { dispatch }) => {
	try {
		const res = await userCustomTestsService.getTestDownloadURI({ id });
		downloadExternalFile(res.resultPdfUrl);
	} catch (e) {
		dispatch(emit({ message: "An error has occurred while downloading the test.", color: "error" }));
	}
});

export const fetchQuestionBankPerformance = createAsyncThunk(
	"questionBank/fetchQuestionBankPerformance",
	async (_, { dispatch, getState }) => {
		const { isLoadingStats } = (
			getState() as {
				questionBank: QuestionBankState;
			}
		).questionBank;

		if (!isLoadingStats) {
			dispatch(setLoading(true));
		}
		try {
			const res = await questionsService.getUserQuestionBankPerformance();
			dispatch(setStateValue({ key: "userQuestionBankPerformance", value: res?.percentage }));
			return res;
		} catch (e) {
			return { error: e };
		} finally {
			dispatch(setLoading(false));
		}
	}
);

export const fetchTests = createAsyncThunk(
	"questionBank/fetchTests",
	async (options: { page?: number; perPage?: number; searchText?: string }, { getState, rejectWithValue }) => {
		const { page, perPage } = (
			getState() as {
				questionBank: QuestionBankState;
			}
		).questionBank;
		const { page: optPage, perPage: optPerPage, searchText: searchText } = options;
		const filters = {};
		if (searchText) {
			filters["name"] = {
				$ilike: `%${searchText}%`
			};
		}
		return await userCustomTestsService
			.find({
				page: optPage ?? page,
				perPage: optPerPage ?? perPage,
				orderBy: { id: "DESC" },
				filters
			})
			.catch(error => {
				return rejectWithValue(error.message);
			});
	}
);

export const fetchSubjects = createAsyncThunk("questionBank/fetchSubjects", async () => {
	return await subjectService.find({
		findAll: true,
		orderBy: { name: "ASC" },
		include: ["lessons"]
	});
});

export const fetchSubjectLessonStats = createAsyncThunk(
	"questionBank/fetchSubjectLessonStats",
	async ({ lessonId, subjectId }: { lessonId?: number; subjectId?: number }) => {
		return await subjectLessonService.getSubjectLessonStats({
			lessonId: lessonId || 0,
			subjectId: subjectId
		});
	}
);

const utilsResetState = getResetState<QuestionBankState>(initialState);

export const questionBankSlice = createSlice({
	name: "questionBank",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoadingStats = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setStateValue: utilsSetStateValue,
		resetState: utilsResetState
	},
	extraReducers: {
		[fetchTests.pending.type]: state => {
			state.isLoadingTests = true;
		},
		[fetchTests.fulfilled.type]: (state, action) => {
			const { page: newPage, perPage: newPerPage, items, totalItems } = action.payload;
			state.tests = items;
			state.page = newPage;
			state.perPage = newPerPage;
			state.totalItems = totalItems;
			state.isLoadingTests = false;
		},
		[fetchTests.rejected.type]: (state, action) => {
			state.isLoadingTests = false;
			state.errorMessage = action.payload;
			state.page = 1;
			state.perPage = 10;
			state.totalItems = 0;
		},
		[fetchSubjects.pending.type]: state => {
			state.isLoading = true;
		},
		[fetchSubjects.fulfilled.type]: (state, action) => {
			const { items } = action.payload;
			state.subjects = items;
			state.isLoading = false;
		},
		[fetchSubjects.rejected.type]: (state, action) => {
			state.isLoading = false;
			state.errorMessage = action.payload;
		},
		[fetchSubjectLessonStats.fulfilled.type]: (state, action) => {
			const { subjectQuestionStats, lessonQuestionStats } = action.payload;
			state.subjectQuestionStats = subjectQuestionStats;
			state.lessonQuestionStats = lessonQuestionStats;
		},
		[fetchSubjectLessonStats.rejected.type]: (state, action) => {
			state.errorMessage = action.payload;
		}
	}
});

export function getFullState(state: RootState): QuestionBankState {
	return state.questionBank;
}

export const { failed, setLoading, setStateValue, resetState } = questionBankSlice.actions;

export default questionBankSlice.reducer;
