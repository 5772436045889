import React, { useEffect, useMemo, useState } from "react";

import Button from "@remar/shared/dist/components/Button";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";
import ExtendedTestResults from "@remar/shared/dist/components/TestResults/ExtendedTestResults/ExtendedTestResults";
import { QuestionBankTestModes } from "@remar/shared/dist/constants";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import { UserCustomTestHistory } from "@remar/shared/dist/models";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";

import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectCourseInfoForSelectedLesson, setSelectedLesson } from "store/features/Course/courseSlice";
import { getFullState as getLessonFullState, getUserLessonAttemptHistory } from "store/features/Lesson/lesson.slice";
import { fetchTestHistory, fetchTests, getFullState } from "store/features/QuestionBank/Test/test.slice";

import { routes } from "core/constants";

import QuestionResultInfo from "../Test/Components/QuestionResultInfo";
import { getPercentageLevel } from "../utils";

const HISTORY_PER_PAGE = 10;
const TestHistory = () => {
	const { currentTest, isLoadingCustomTestAttempts, customTestAttemptsHistory } = useAppSelector(getFullState);
	const { selectedLessonAttemptsHistory, loadingSelectedLessonAttemptsHistory } = useAppSelector(getLessonFullState);
	const { sectionLesson } = useAppSelector(selectCourseInfoForSelectedLesson);
	const history = useHistory();
	const { id, sectionId } = useParams<{ id: string; sectionId: number }>();
	const [attemptDetails, setAttemptDetails] = useState<UserCustomTestHistory | null>();
	const isTestOnlyLesson = history.location.pathname.includes(routes.lesson.getPath());
	const historyData = isTestOnlyLesson ? selectedLessonAttemptsHistory : customTestAttemptsHistory;
	const { totalItems, perPage, page } = historyData;
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (isTestOnlyLesson) {
			dispatch(setSelectedLesson(+id));
			dispatch(getUserLessonAttemptHistory({ sectionLessonId: sectionId, page: 1, perPage: HISTORY_PER_PAGE }));
		} else {
			dispatch(
				fetchTests({
					id
				})
			);
			dispatch(fetchTestHistory({ id, page: 1, perPage: HISTORY_PER_PAGE }));
		}
	}, [isTestOnlyLesson, dispatch, id, currentTest.id]);

	const breadcrumbs = useMemo(
		() => [
			{
				title: isTestOnlyLesson ? "Course" : "Question Bank",
				key: 0,
				link: isTestOnlyLesson ? `${routes.course.getPath()}` : `${routes.questionBank.getPath()}`
			},
			{
				title: currentTest.name || sectionLesson?.lesson?.name,
				key: 1,
				link: isTestOnlyLesson ? `${routes.course.getPath()}` : `${routes.questionBank.getPath()}/test/${id}`
			},
			{ title: "History", key: 2 }
		],
		[currentTest.name, sectionLesson]
	);

	const tableColumns: Array<IColumn<UserCustomTestHistory>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Test Attempt</ColumnHeader>,
				Cell: ({
					rowData: {
						snapshot: { name, lesson }
					},
					rowIndex
				}) => {
					const attemptNumber =
						customTestAttemptsHistory.page > 1
							? (customTestAttemptsHistory.page - 1) * HISTORY_PER_PAGE + (rowIndex + 1)
							: rowIndex + 1;
					return (
						<StyledCellWrapper>
							<StyledCellText>{`${name || lesson?.name} #${attemptNumber}`}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "test_attempt"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Test Results</ColumnHeader>,
				Cell: ({ rowData }) => {
					const passed = rowData.snapshot.passed || rowData.passed;
					const type = rowData.snapshot.type;
					const percentageGrade = (rowData.snapshot.percentageGrade || rowData.percentageGrade || 0) as number;
					return (
						<StyledCellWrapper>
							<StyledCellText>
								{type?.id === QuestionBankTestModes.CAT
									? passed
										? "Above Passing Standard"
										: "Below Passing Standard"
									: getPercentageLevel(percentageGrade * 100)}
							</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "test_results"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Test Date</ColumnHeader>,
				Cell: ({
					rowData: {
						snapshot: { createdAt }
					}
				}) => (
					<StyledCellWrapper>
						<StyledCellText>{formatDate(new Date(createdAt as string))}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "test_date"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Test Time</ColumnHeader>,
				Cell: ({
					rowData: {
						snapshot: { createdAt }
					}
				}) => (
					<StyledCellWrapper>
						<StyledCellText>{format(new Date(createdAt as string), "hh:mm aa")}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "test_time"
			},
			{
				alignment: "left",
				Cell: ({ rowData }) => (
					<Button variant="outlined" color="basic" onClick={() => setAttemptDetails(rowData)}>
						See Details
					</Button>
				),
				dataKey: "results"
			}
		],
		[historyData, isTestOnlyLesson]
	);

	return (
		<>
			<WithTableContentLayout
				heading={currentTest.name || sectionLesson?.lesson?.name}
				breadcrumb={breadcrumbs}
				actions={
					<Button
						variant="filled"
						color="secondary"
						onClick={() => {
							history.goBack();
						}}
					>
						Back
					</Button>
				}
				isLoading={isLoadingCustomTestAttempts || loadingSelectedLessonAttemptsHistory}
				tableColumns={tableColumns}
				data={historyData?.items}
				emptyState={<EmptyState description="No History found" />}
				onChangePage={page => {
					isTestOnlyLesson
						? dispatch(
								dispatch(getUserLessonAttemptHistory({ sectionLessonId: sectionId, page, perPage: HISTORY_PER_PAGE }))
						  )
						: dispatch(fetchTestHistory({ id, page, perPage: HISTORY_PER_PAGE }));
				}}
				totalItems={totalItems}
				totalEntities={totalItems}
				tableTitle="Test History"
				perPage={perPage}
				page={page}
			/>
			{attemptDetails && (
				<ExtendedTestResults
					userQuestion={attemptDetails.snapshot.data?.questions || attemptDetails.snapshot.lesson?.questions}
					result={attemptDetails.snapshot.userQuestionAttempts || attemptDetails.userQuestionAttempts}
					currentTest={isTestOnlyLesson ? attemptDetails : currentTest}
					onClose={() => setAttemptDetails(null)}
					isLoading={false}
					QuestionResultInfoComponent={QuestionResultInfo}
				/>
			)}
		</>
	);
};

export default TestHistory;
