import { BaseModel } from "./base.model";
import { IPaymentDiscount } from "./coupon.model";
import { Course } from "./course.model";
import { Lesson } from "./lesson.model";
import { UserShippingDetailsItem } from "./userShippingDetailsItem.model";
import { UserSubscription } from "./userSubscription.model";

export interface LocationTheme extends Pick<BaseModel, "id"> {
	color: string;
	name: string;
	data: Record<string, unknown>;
}

interface UserType extends BaseModel {
	name: string;
	isActive: boolean;
	isEditable: boolean;
	canSignUp: boolean;
	signupSubscriptionTypeId?: number;
}

export interface UserShippingDetails {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	address1: string;
	fullName: string;
	address2: string;
	city: string;
	phoneNumber: string;
	state: string;
	zipCode: string;
	userId: number;
	countryId: number;
}

export interface UpdateUser extends Pick<User, "firstName" | "lastName" | "email"> {}
export interface User extends BaseModel {
	accountStatusId: number;
	accountStatus: AccountStatus;
	firstName: string;
	lastName: string;
	email: string;
	userAuthTypeId?: number;
	unverifiedEmail?: string;
	schoolId: number;
	hasTakenIntro: boolean;
	examInfo?: UserExamInfo;
	currentAccessControlPoints?: Record<string, unknown>[];
	currentlyAllowedTrialCourses?: Course[];
	currentlyAllowedFullCourses?: Course[];
	currentlyAllowedLessons?: Lesson[];
	subscriptions?: UserSubscription[];
	expiredSubscriptions?: UserSubscription[];
	managedLocations?;
	allowedLocations?: AllowedLocations[];
	canAccessCourse: boolean;
	canAccessQuiz: boolean;
	canAccessQuestionBank: boolean;
	acceptedTermsAndConditions: boolean;
	trialAccess: boolean;
	assignedUserTypes?: UserType[];
	profileImageUrl: string;
	userShippingDetails?: UserShippingDetails;
	nextUserSubscriptionType?: UserSubscription;
	paymentAndDiscount: IPaymentDiscount;
	badges: Array<Badge>;
	isCATAvailable: boolean;
	redirectUrl: string;
}

export interface UserInfo extends Pick<User, "firstName" | "lastName" | "email" | "profileImageUrl"> {
	fullName?: string;
	schoolName: string;
	phoneNumber: string;
	address: string;
	acceptedTermsAndConditions?: boolean;
	shippingDetails?: UserShippingDetailsItem;
	badges?: Badge[];
}

interface AllowedLocations extends BaseModel {
	canSignUp: boolean;
	isCommon: boolean;
	name: string;
	themeId: number | null;
	totalQuestions: number;
	totalStudents: number;
	theme?: LocationTheme;
}

export enum AccountStatusType {
	SignupPending = "SignupPending",
	Active = "Active"
}
export interface AccountStatus extends BaseModel {
	deletedAt: string | null;
	name: string;
	label: AccountStatusType;
	userLoginAllowed: boolean;
}

export interface UserExamInfo {
	nclexTimesTaken?: number;
	dateOfExam?: string;
}

export interface UserCertificate extends BaseModel {
	courseId: number;
	certificatePdf: string;
	userId: number;
	percentage: number | null;
	certificatePdfUrl: string;
}

export interface Badge extends BaseModel {
	userId: number;
	typeId: UserBadgeTypeEnum;
}

export enum UserBadgeTypeEnum {
	ReMarReadyBadge = 1,
	InactivityBadge = 2
}

export interface BadgeInfo {
	catPassed: boolean;
	isCourseComplete: boolean;
	courseCompletionPercentage: number;
	timeSpentTraining: number;
	timeSpentRequired: number;
	percentage: number;
	receivedOn: string;
}

export interface IPasswordBoxValidation {
	characters: boolean;
	lowerCase: boolean;
	upperCase: boolean;
	specialCharacter: boolean;
	numbers: boolean;
}
