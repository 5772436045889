import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "@remar/shared/dist/components/Button";

import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState as getFullBillingState } from "store/features/Billing/BillingSlice";

import {
	buyExtraCatTests,
	fetchCATPaymentInfo,
	getFullState,
	setStateValue
} from "store/features/QuestionBank/CreateTest/createTest.slice";

import GirlWithLaptop from "assets/images/guest-create-acc.svg";
import { routes } from "core/constants";

import { getCallbackUrl } from "modules/utils";

import OneTimePayment from "../../Billings/OneTimePayment";

import {
	CATPaymentAmount,
	CATPaymentOrderDetails,
	CATPaymentOrderSeparator,
	CATPaymentOrderTotal,
	CATPaymentPrice,
	CatPaymentOrderSummary,
	PaymentStatusDescription,
	PaymentStatusImage,
	PlusMinus,
	YourTotal
} from "../styles";

export const CATTestOrderPayment = ({ courseId, onClose }) => {
	const dispatch = useAppDispatch();

	const { catPaymentInfo, catPaymentResult } = useAppSelector(getFullState);
	const { paymentMethods } = useAppSelector(getFullBillingState);

	useEffect(() => {
		return () => {
			dispatch(
				setStateValue({
					key: "catPaymentResult",
					value: undefined
				})
			);
		};
	}, [dispatch]);

	useEffect(() => {
		if (courseId) {
			dispatch(fetchCATPaymentInfo({ courseId }));
		}
	}, [courseId, dispatch]);

	const [isLoading, setIsLoading] = useState(false);

	const [quantity, setQuantity] = useState(1);

	const history = useHistory();
	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);
	const sideEffect = useCallback(() => {
		if (callBackUrl) {
			window.location.replace(callBackUrl);
		}
	}, [callBackUrl]);
	const handlePayment = () => {
		setIsLoading(true);

		dispatch(
			buyExtraCatTests({
				paymentProviderPaymentMethodIdentifier: paymentMethods?.id ?? "",
				quantity,
				catPaymentId: catPaymentInfo?.catPaymentId ?? 0,
				sideEffect,
				stripe
			})
		);
	};

	const handleTakeTest = () => {
		onClose();

		dispatch(
			setStateValue({
				key: "catPaymentResult",
				value: undefined
			})
		);
	};
	const succeeded = !!(catPaymentResult?.amount ?? 0 > 0);
	return (
		<OneTimePayment
			open={true}
			width={"748px"}
			isLoading={isLoading}
			handlePayment={handlePayment}
			handleClose={onClose}
			succeeded={succeeded}
			successNote={
				<PaymentStatusDescription bold>
					{catPaymentInfo?.name}: {quantity} x ${catPaymentInfo?.price}
				</PaymentStatusDescription>
			}
			successBody={
				<CatPaymentOrderSummary>
					<PaymentStatusImage src={GirlWithLaptop} alt="" />
					<PaymentStatusDescription bold>
						You have {quantity} additional CAT tests. <br />
						Test your knowledge now!
					</PaymentStatusDescription>
					<Button variant={"filled"} color={"primary"} size="medium" onClick={handleTakeTest}>
						Take CAT Test
					</Button>
					<Button
						variant={"ghost"}
						color={"secondary"}
						size="medium"
						onClick={() => history.push(routes.questionBank.getPath())}
					>
						Go to Homepage
					</Button>
				</CatPaymentOrderSummary>
			}
			failed={!succeeded && catPaymentResult !== undefined}
			onTryAgain={() => {
				setIsLoading(false);
				dispatch(
					setStateValue({
						key: "catPaymentResult",
						value: undefined
					})
				);
			}}
			orderSummary={
				<>
					{catPaymentInfo?.name}
					<CATPaymentOrderDetails>
						<CATPaymentPrice>${catPaymentInfo?.price}</CATPaymentPrice>
						<PlusMinus size="tiny" onClick={() => !isLoading && setQuantity(Math.max(1, quantity - 1))}>
							-
						</PlusMinus>
						<CATPaymentAmount>{quantity}</CATPaymentAmount>
						<PlusMinus size="tiny" onClick={() => !isLoading && setQuantity(quantity + 1)}>
							+
						</PlusMinus>
					</CATPaymentOrderDetails>
					<CATPaymentOrderSeparator />
					<YourTotal>Your total</YourTotal>
					<CATPaymentOrderTotal>
						$ {quantity && catPaymentInfo?.price ? Math.round(quantity * catPaymentInfo?.price * 100) / 100 : 0}
					</CATPaymentOrderTotal>
				</>
			}
		/>
	);
};
