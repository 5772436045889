import React, { useEffect, useState } from "react";

import { Modal } from "@material-ui/core";

import { useAppSelector } from "store";
import { getFullState } from "store/features/Auth/authSlice";

import { GLOBAL_CONSTANTS } from "../../../../constants";
import StudentForm from "../../../Course/StudentForm";
import BaseLayout from "../../BaseLayout";
const StudentModal = ({ modal, closeModal }) => (
	<Modal
		style={{
			alignItems: "center",
			justifyContent: "center",
			display: "flex"
		}}
		disableEnforceFocus
		disableAutoFocus
		open={!!modal}
	>
		<StudentForm closeModal={closeModal} />
	</Modal>
);
const GlobalWrapper = ({ children }) => {
	const { user, paymentDiscount, isLoggedIn } = useAppSelector(getFullState);
	const showSchoolModal = GLOBAL_CONSTANTS.TOGGLE_SCHOOL_MODAL;
	const [studentModal, setStudentModal] = useState(user && showSchoolModal && (!user.schoolId || !user.hasTakenIntro));

	useEffect(() => {
		if (user && showSchoolModal) {
			setStudentModal(!user.schoolId || !!paymentDiscount || !user.hasTakenIntro);
		}
	}, [showSchoolModal, user, paymentDiscount]);

	const closeModal = () => setStudentModal(false);
	if (isLoggedIn) {
		return (
			<BaseLayout>
				{children}
				<StudentModal modal={studentModal} closeModal={closeModal} />
			</BaseLayout>
		);
	}
	return (
		<>
			{children}
			<StudentModal modal={studentModal} closeModal={closeModal} />
		</>
	);
};

export default GlobalWrapper;
