import { passwordREGEX } from "@remar/shared/dist/utils/serviceUtils";
import * as Yup from "yup";

export const TrialEditProfileSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	phoneNumber: Yup.string()
		.max(20, "Phone number must be at most 20 characters")
		.min(7, "Phone number must be at least 7 characters")
		.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
		.nullable()
});

export const EditProfileSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	lastName: Yup.string().min(2, "Its too short").max(50, "It must be at most 50 characters long").required("Required"),
	phoneNumber: Yup.string()
		.max(20, "Phone number must be at most 20 characters")
		.min(7, "Phone number must be at least 7 characters")
		.required("Required")
		.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
});

export const ChangePwdSchema = Yup.object().shape({
	currentPassword: Yup.string().required("Required"),
	newPassword: Yup.string()
		.required("Required")
		.matches(
			passwordREGEX,
			"The password must contain at least 6 characters, 1 uppercase letter, 1 lowercase letter , 1 number and 1 special character."
		)
		.test(
			"new-and-old-password-should-not-match",
			"New password shouldn’t match with the current password",
			function (value) {
				return this.parent.currentPassword !== value;
			}
		),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("newPassword"), null], "Passwords must match")
		.required("Required")
});
