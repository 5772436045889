import React from "react";

import { Editor } from "@tinymce/tinymce-react";

type TinyMCEProps = {
	value: string;
	// eslint-disable-next-line no-unused-vars
	onChange: (content: string) => void;
};

const TinyMCE = (props: TinyMCEProps) => {
	const handleEditorChange = content => {
		props.onChange(content);
	};

	return (
		<Editor
			apiKey="g59pn03wut4tl6zj8p4fzatvtxmr15hmgr43x6y94nl3xfzp"
			init={{
				min_height: 250,
				width: "100%",
				menubar: false,
				branding: false,
				plugins: ["lists link anchor", "table"],
				toolbar: "bold italic underline  backcolor forecolor  | link | bullist numlist | table |",
				content_style: "body { font-family: var(--openSans); sans-serif; font-size:14px }",
				table_appearance_options: false,
				table_toolbar: "",
				table_tab_navigation: false,
				table_sizing_mode: "relative",
				table_responsive_width: true,
				statusbar: false
			}}
			value={props.value}
			onEditorChange={handleEditorChange}
		/>
	);
};

export default TinyMCE;
