import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Star } from "@material-ui/icons";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import Button from "@remar/shared/dist/components/Button";
import InfoBlock from "@remar/shared/dist/components/InfoBlock";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";
import {
	ColumnHeader,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle,
	THeaderWrapper
} from "@remar/shared/dist/components/Table/styles";

import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import ExtendedTestResults from "@remar/shared/dist/components/TestResults/ExtendedTestResults";
import { QuestionBankTestModes, QuestionDifficultyLevelTiers, QuestionTypes } from "@remar/shared/dist/constants";
import useSearchParams from "@remar/shared/dist/hooks/useSearchParams";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import { Question, UserCustomTest } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";
import useAnalyticsEventTracker from "hooks/googleAnalytics";

import isEmpty from "lodash/isEmpty";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { selectIsTrialUser } from "store/features/Auth/authSlice";
import {
	fetchTests as fetchSingleTest,
	getIsLoading,
	retakeTest,
	setCurrentTest
} from "store/features/QuestionBank/Test/test.slice";

import {
	downloadTestResults,
	fetchQuestionBankPerformance,
	fetchQuestionBankStats,
	fetchSubjects,
	fetchTests,
	getFullState,
	resetState
} from "store/features/QuestionBank/questionBank.slice";

import { ReactComponent as IconQuestionsRemaining } from "assets/icons/icon-questions-remaining.svg";
import { ReactComponent as IconQuestionsTaken } from "assets/icons/icon-questions-taken.svg";
import { ReactComponent as IconTotalQuestions } from "assets/icons/icon-total-questions.svg";
import { routes } from "core/constants";

import RadialBar from "./Charts/RadialBar";

import SemiRadialBar from "./Charts/SemiRadialBar";
import CreateTestBanner from "./CreateTestBanner";
import MotivationalQuotes from "./MotivationalQuotes";

import SubjectLessonStats from "./SubjectLessonStats";

import QuestionResultInfo from "./Test/Components/QuestionResultInfo";

import {
	AnswersChart,
	AnswersContainer,
	AnswersProgress,
	CardSubInfo,
	NoTestHistoryIcon,
	NoTestsContainer,
	PerformanceContainer,
	QuestionInfoContainer,
	ScoreDetailInfo,
	ScoreInfo,
	ScoreLevel,
	ScoreTitle,
	ScoreTitleContainer
} from "./styles";
import { getPercentageLevel } from "./utils";
const scoreCategories = {
	Low: {
		title: "Low",
		color: "rgba(213, 75, 91, 0.87)",
		desc: "Your score demonstrates a low competency of the core nursing content for the NCLEX examination.  Prior to taking your state board exams you are encouraged to seek further remediation by reviewing the NCLEX Virtual Trainer to boost your content knowledge. Focusing more on the content will help you achieve a higher level of knowledge and improve your critical thinking skills and NCLEX readiness."
	},
	Fair: {
		color: "rgba(219, 172, 49, 0.87)",
		title: "Fair",
		desc: " Your score is right on the borderline which indicates a basic competency of the core nursing content for the NCLEX examination, however there are some inconsistences. For improved results, use the NCLEX Virtual Trainer and Question Bank. Try to refocus on your weak areas by studying the content to improve your critical thinking and achieve a higher level of NCLEX readiness prior to testing."
	},
	Good: {
		title: "Good",
		color: "rgba(96, 224, 66, 0.87)",
		desc: "Your score reflects a firm knowledge of the core nursing content for the NCLEX examination. Your overall performance is good and you should consider testing in the near future. With continued study with the NCLEX Virtual Trainer and Question Bank you can reach an excellent level of nursing content knowledge and critical thinking. You are on the right track, keep up the good work!"
	},
	Excellent: {
		title: "Excellent",
		color: "rgba(70, 217, 68, 0.87)",
		desc: "Your score is reflective of an excellent knowledge of the core nursing content for the NCLEX examination! You are able to critically think and consistently choose safe outcomes. Try not to second guess yourself - your proficiency level will only increase with further study as you prepare to test.  Great job!"
	}
};

const QuestionBank = () => {
	const theme = useTheme<IExtendedTheme>();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const params = useSearchParams();
	const testId = params.get("test");
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const analytics = useAnalyticsEventTracker("Question Bank");

	const isTrial: boolean = useAppSelector(selectIsTrialUser);

	useEffect(() => {
		dispatch(fetchTests({}));
		if (!isTrial) {
			dispatch(fetchQuestionBankStats({}));
			dispatch(fetchQuestionBankPerformance());
			dispatch(fetchSubjects());
		}
		return () => {
			dispatch(resetState());
		};
	}, [isTrial, dispatch]);

	const [testPreviewData, setTestPreviewData] = useState({});
	const [showRetakeTestModal, setShowRetakeTestModal] = useState(false);

	const [currentTestId, setCurrentTestId] = useState(0);
	const loading: boolean = useAppSelector(getIsLoading);
	const {
		isLoadingTests,
		perPage,
		page,
		tests,
		totalItems,
		isLoadingStats,
		questionBankStats,
		userQuestionBankPerformance
	} = useAppSelector(getFullState);

	const [searchText, setSearchText] = useState("");
	const questionCount = (questions: Question[], typeId: number): number => {
		const questionLength =
			typeId === QuestionBankTestModes.CAT && questions.length > 0 ? questions?.length - 1 : questions?.length;
		// Five questions for each case study, including the case study question count
		return questionLength + (questions?.filter(f => f.typeId === QuestionTypes.CaseStudy)?.length || 0) * 5;
	};

	const previewTestResults = testId => {
		dispatch(
			fetchSingleTest({
				id: testId,
				sideEffect: testData => {
					if (!testData) return;
					analytics({ eventName: "preview_test", eventIdentifier: testData.id.toString() });
					dispatch(setCurrentTest(testData));
					setTestPreviewData({
						currentTest: testData,
						typeId: testData.typeId,
						quizResult: testData.userQuestionAttempts,
						quizAttemptedQuestions: testData.data.questions,
						...(testData.typeId === QuestionBankTestModes.CAT && {
							contentAreas: testData.data.result?.contentAreas
						}),
						...(testData.typeId === QuestionBankTestModes.CAT && {
							catAttemptedQuestionsCount: testData.userQuestionAttempts.length
						})
					});
				}
			})
		);
	};

	useEffect(() => {
		if (testId) {
			previewTestResults(testId);
		}
	}, [testId]);

	const tableColumns: Array<IColumn<UserCustomTest>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Test Name</ColumnHeader>,
				width: isMobile ? 300 : 600,
				Cell: ({ rowData: { name } }) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "testName"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Test Type</ColumnHeader>,
				Cell: ({ rowData: { typeId } }) => (
					<StyledCellWrapper>
						<StyledCellText>{QuestionBankTestModes[typeId]}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "testType"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Questions Number</ColumnHeader>,
				Cell: ({
					rowData: {
						data: { questions },
						typeId
					}
				}) => (
					<StyledCellWrapper center>
						<StyledCellText>{questionCount(questions!, typeId)}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "questionsNumber"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Difficulty</ColumnHeader>,
				Cell: ({ rowData: { difficultyTierId } }) => (
					<StyledCellWrapper>
						<StyledCellText>{QuestionDifficultyLevelTiers[difficultyTierId as number] ?? "-"}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "difficulty"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Creation Date</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => (
					<StyledCellWrapper>
						<StyledCellText>{formatDate(createdAt!)}</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "createdAt"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData }) => {
					return (
						<ActionMenu
							customMenuItems={[
								{
									label: "Preview",
									onClick: () => previewTestResults(rowData.id),
									visible: !rowData.inProgress,
									disabled: false
								},
								{
									label: "Retake",
									onClick: () => {
										setCurrentTestId(rowData.id);
										setShowRetakeTestModal(true);
									},
									visible: !rowData.inProgress && rowData.typeId !== QuestionBankTestModes.CAT,
									disabled: false
								},
								{
									label: "Continue Test",
									onClick: () => {
										const { id } = rowData;
										analytics({ eventName: "continue_test", eventIdentifier: rowData.id.toString() });
										history.push(`${routes.questionBank.getPath()}/test/${id}`);
									},
									visible: !!rowData.inProgress,
									disabled: false
								},
								{
									label: "Download",
									onClick: () => dispatch(downloadTestResults(rowData.id)),
									visible: rowData.typeId === QuestionBankTestModes.CAT && !rowData.inProgress,
									disabled: false
								}
							]}
						/>
					);
				},
				dataKey: "menu"
			}
		],
		[analytics, dispatch, history, isMobile]
	);

	const handleSearchBarChange = useCallback(
		searchText => {
			return dispatch(fetchTests({ searchText, page: 1 }));
		},
		[dispatch]
	);

	const getAnswerPercentage = useCallback(
		answerCount => {
			const totalAnswers =
				questionBankStats.userAnswersCount.correctAnswers +
				questionBankStats.userAnswersCount.incorrectAnswers +
				questionBankStats.userAnswersCount.partialCorrectAnswers;
			const divisionResult = answerCount / totalAnswers;
			return isNaN(divisionResult) ? 0 : Math.round(divisionResult * 100);
		},
		[
			questionBankStats.userAnswersCount.correctAnswers,
			questionBankStats.userAnswersCount.incorrectAnswers,
			questionBankStats.userAnswersCount.partialCorrectAnswers
		]
	);

	const redirectToTest = () => {
		analytics({ eventName: "retake_test_from_list_view", eventIdentifier: `${currentTestId}` });
		history.push(`${routes.questionBank.getPath()}/test/${currentTestId}`);
	};

	const questionInfos = useMemo(
		() => [
			{
				bgColor:
					theme.palette.type === "dark" ? theme.palette.colors.primary[1200] : theme.palette.InfoCard.backgroundColor,
				title: "Total Questions",
				text: questionBankStats.userQuestionsCount?.totalquestions,
				IconSvg: IconTotalQuestions
			},
			{
				bgColor: theme.palette.type === "dark" ? "hsl(201,78%,12%)" : theme.palette.InfoCard.backgroundColor,
				title: "Questions Taken",
				text: questionBankStats.userQuestionsCount?.totalquestionAttempts,
				IconSvg: IconQuestionsTaken
			},
			{
				bgColor: theme.palette.type === "dark" ? "hsl(307,32%,15%)" : theme.palette.InfoCard.backgroundColor,
				title: "Questions Remaining",
				text: questionBankStats.userQuestionsCount?.totalRemainingQuestions,
				IconSvg: IconQuestionsRemaining
			}
		],
		[
			questionBankStats.userQuestionsCount?.totalRemainingQuestions,
			questionBankStats.userQuestionsCount?.totalquestionAttempts,
			questionBankStats.userQuestionsCount?.totalquestions
		]
	);

	const chartAnswers = useMemo(
		() => [
			{
				label: "Correct Answers",
				count: questionBankStats.userAnswersCount.correctAnswers,
				percentage: getAnswerPercentage(questionBankStats.userAnswersCount.correctAnswers),
				primaryColor: theme.palette.RadialChart.sucess.primaryColor,
				secondaryColor: theme.palette.RadialChart.sucess.secondaryColor
			},
			{
				label: "Partially Correct Answers",
				count: questionBankStats.userAnswersCount.partialCorrectAnswers,
				percentage: getAnswerPercentage(questionBankStats.userAnswersCount.partialCorrectAnswers),
				primaryColor: theme.palette.RadialChart.warning.primaryColor,
				secondaryColor: theme.palette.RadialChart.warning.secondaryColor
			},
			{
				label: "Incorrect Answers",
				count: questionBankStats.userAnswersCount.incorrectAnswers,
				percentage: getAnswerPercentage(questionBankStats.userAnswersCount.incorrectAnswers),
				primaryColor: theme.palette.RadialChart.danger.primaryColor,
				secondaryColor: theme.palette.RadialChart.danger.secondaryColor
			}
		],
		[
			getAnswerPercentage,
			questionBankStats.userAnswersCount.correctAnswers,
			questionBankStats.userAnswersCount.incorrectAnswers,
			questionBankStats.userAnswersCount.partialCorrectAnswers
		]
	);

	const getScoreLevel = useMemo(() => {
		const percentage = getAnswerPercentage(questionBankStats.userAnswersCount.correctAnswers);
		return getPercentageLevel(percentage);
	}, [getAnswerPercentage, questionBankStats.userAnswersCount.correctAnswers]);

	return (
		<Wrapper
			heading="Question Bank"
			actions={
				<Button
					variant="filled"
					color={"primary"}
					onClick={() => {
						analytics({ eventName: "start_creating_test" });
						history.push(`${routes.questionBank.getPath()}/createTest`);
					}}
				>
					Create New Test
				</Button>
			}
		>
			{isTrial ? (
				<CreateTestBanner />
			) : (
				<>
					<QuestionInfoContainer>
						{isLoadingStats ? (
							<ContentLoader />
						) : (
							questionInfos.map(({ bgColor, title, text, IconSvg }, i) => (
								<InfoBlock key={i} customBgColor={bgColor} title={title} text={text || "0"} IconSvg={IconSvg} streth />
							))
						)}
					</QuestionInfoContainer>
					{!isLoadingStats ? (
						<AnswersContainer>
							<AnswersChart>
								{chartAnswers.map((x, i) => (
									<RadialBar key={i} data={x} />
								))}
							</AnswersChart>

							<AnswersProgress>
								<SemiRadialBar data={getAnswerPercentage(questionBankStats.userAnswersCount.correctAnswers)} />
								<ScoreInfo>
									<ScoreTitleContainer>
										<ScoreTitle> Your Total Score</ScoreTitle>
										<ScoreLevel color={scoreCategories[getScoreLevel].color}>
											{scoreCategories[getScoreLevel].title}&nbsp;(
											{getAnswerPercentage(questionBankStats.userAnswersCount.correctAnswers)}){" "}
										</ScoreLevel>
									</ScoreTitleContainer>
									<ScoreDetailInfo>{scoreCategories[getScoreLevel].desc}</ScoreDetailInfo>

									<PerformanceContainer>
										<Star style={{ width: "25px", height: "25px", fill: "#4a99f9" }} />
										<CardSubInfo>
											You are performing better than {userQuestionBankPerformance}% of your peers!
										</CardSubInfo>
									</PerformanceContainer>
								</ScoreInfo>
							</AnswersProgress>
						</AnswersContainer>
					) : null}

					<SubjectLessonStats />
					<MotivationalQuotes />
				</>
			)}
			<>
				<THeaderWrapper>
					<THeaderTitle>Test History</THeaderTitle>
					<SearchBarComponent
						inputValue={searchText}
						setInputValue={setSearchText}
						onChange={handleSearchBarChange}
						isDebounced={true}
						debounceTime={1000}
						placeHolder={"Type to filter by keywords"}
						autoFocus={false}
					/>
				</THeaderWrapper>
			</>
			<>
				{isLoadingTests ? (
					<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
						<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
					</Box>
				) : tests.length ? (
					<>
						<MaterialTable columns={tableColumns} data={tests} height={800} />
						<TablePagination
							count={totalItems}
							page={page}
							onChange={(_, page) =>
								dispatch(
									fetchTests({
										page,
										...(searchText && { searchText })
									})
								)
							}
							rowsPerPage={perPage}
						/>
					</>
				) : (
					<NoTestsContainer>
						<NoTestHistoryIcon />
						<Typography variant="h1">No tests history yet</Typography>
						<Button
							variant={"filled"}
							color={"primary"}
							onClick={() => history.push(`${routes.questionBank.getPath()}/createTest`)}
						>
							Create New Test
						</Button>
					</NoTestsContainer>
				)}
			</>

			{!isEmpty(testPreviewData) && (
				<ExtendedTestResults
					testModeType={testPreviewData["typeId"]}
					userQuestion={testPreviewData["quizAttemptedQuestions"]}
					result={testPreviewData["quizResult"]}
					contentAreas={testPreviewData["contentAreas"]}
					catAttemptedQuestionsCount={testPreviewData["catAttemptedQuestionsCount"]}
					currentTest={testPreviewData["currentTest"]}
					onClose={() => setTestPreviewData({})}
					isLoading={false}
					QuestionResultInfoComponent={QuestionResultInfo}
				/>
			)}
			<SimpleModal
				theme={theme}
				title={"Retake Test"}
				open={showRetakeTestModal}
				onClose={() => setShowRetakeTestModal(false)}
				text={"Are you sure you want to retake the test?"}
				footer={
					<Box display="flex" gridGap={10}>
						<Button
							variant="outlined"
							color={"basic"}
							size="medium"
							onClick={() => setShowRetakeTestModal(false)}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							loading={loading}
							onClick={() => dispatch(retakeTest({ userCustomTestId: +currentTestId, sideEffect: redirectToTest }))}
							variant="filled"
							color="primary"
							disabled={loading}
						>
							Confirm
						</Button>
					</Box>
				}
			/>
		</Wrapper>
	);
};

export default QuestionBank;
