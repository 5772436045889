import React, { useState } from "react";

import { Checkbox, Modal } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import { useSimpleModalStyles } from "@remar/shared/dist/modals/SimpleModal/styles";

import {
	BuyButtons,
	PaymentAgreementRow,
	PaymentCardColumn,
	PaymentContent,
	PaymentOrderHeader,
	PaymentOrderSummary
} from "./styles";

import {
	PaymentContainer,
	PaymentStatusDescription,
	PaymentStatusErrorIcon,
	PaymentStatusHeader,
	PaymentStatusSuccessIcon
} from "../../QuestionBank/styles";

import PaymentMethods from "../PaymentMethods";

const OneTimePayment = ({
	open,
	width,
	height = "auto",
	handleClose,
	handlePayment,
	isLoading,
	succeeded = false,
	successBody,
	successNote,
	failed = false,
	onTryAgain,
	orderSummary
}: {
	open: boolean;
	handleClose: () => void;
	handlePayment: () => void;
	isLoading: boolean;
	succeeded: boolean;
	successBody: React.ReactNode | null;
	failed: boolean;
	onTryAgain: () => void;
	orderSummary: React.ReactNode | null;
	successNote?: React.ReactNode | null;
	width?: string | number;
	height?: string | number;
}) => {
	const [isCardModalVisible, setIsCardModalVisible] = useState(false);
	const [isAgreed, setIsAgreed] = useState(false);
	const simpleModalClasses = useSimpleModalStyles();

	return (
		<Modal className={simpleModalClasses.modal} disableEnforceFocus disableAutoFocus open={open} onClose={handleClose}>
			<PaymentContent width={width} height={height}>
				{succeeded ? (
					<>
						<PaymentCardColumn>
							<PaymentStatusSuccessIcon />
							<PaymentStatusHeader>Payment Successful</PaymentStatusHeader>
							<PaymentStatusDescription>Congratulations on your purchase!</PaymentStatusDescription>
							{successNote}
						</PaymentCardColumn>
						{successBody}
					</>
				) : (
					<PaymentContainer>
						<PaymentCardColumn>
							{!failed ? (
								<>
									<PaymentMethods
										onEdit={() => setIsCardModalVisible(true)}
										modal={isCardModalVisible}
										setModal={setIsCardModalVisible}
									/>
									<PaymentAgreementRow>
										<Checkbox checked={isAgreed} onChange={e => setIsAgreed(e.target.checked)} /> I agree to the Terms &
										Conditions
									</PaymentAgreementRow>
									<BuyButtons>
										<Button
											variant={"filled"}
											color={"primary"}
											size="medium"
											disabled={!isAgreed || isLoading}
											onClick={handlePayment}
											loading={isLoading}
										>
											Complete Purchase
										</Button>
									</BuyButtons>
								</>
							) : (
								<>
									<PaymentStatusErrorIcon />
									<PaymentStatusHeader>Something Went Wrong</PaymentStatusHeader>
									<PaymentStatusDescription>
										We are not able to process your payment.
										<br />
										Please try again.
									</PaymentStatusDescription>
									<Button size="medium" onClick={onTryAgain}>
										Try again
									</Button>
								</>
							)}
						</PaymentCardColumn>
						<PaymentOrderSummary>
							<PaymentOrderHeader>Order Summary</PaymentOrderHeader>
							{orderSummary}
						</PaymentOrderSummary>
					</PaymentContainer>
				)}
			</PaymentContent>
		</Modal>
	);
};

export default OneTimePayment;
