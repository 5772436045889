import React, { useCallback, useEffect, useRef, useState } from "react";

import { Box, Button, createStyles, makeStyles } from "@material-ui/core";

import ContentEditable from "react-contenteditable";
import styled from "styled-components";

import HotspotHighlightSVG from "../../assets/icons/icon-hotspot-highlight.svg";

import { IExtendedTheme } from "../../theme/default";
import { TransparentButton } from "../../ui/Buttons/styles";

const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		editable: {
			fontSize: "14px",
			color: ({ student }: { student: boolean }) =>
				`${student ? theme.palette.colors.info[750] : theme.palette.text.primary}`,
			padding: "8px",
			minHeight: "60px",
			overflowWrap: "anywhere",
			textAlign: "left",
			fontFamily: "var(--openSans)",

			"& .highlight": {
				background: "hsl(50,100%,86%)",
				color: "hsl(224,7%,32%)",
				padding: "4px",
				borderRadius: "3px",
				"& .remove": {
					display: "inline-block",
					borderRadius: "50%",
					height: "16px",
					width: "16px",
					color: "#fff",
					background: "hsl(29,85%,51%)",
					marginLeft: "3px",
					cursor: "pointer",
					lineHeight: "16px",
					textAlign: "center"
				}
			}
		},
		highlightIcon: {
			cursor: "pointer"
		}
	})
);

const StyledContentEditable = styled(ContentEditable)`
	border: 1px solid rgb(151, 151, 151);
	border-radius: 4px;
`;

const StyledMatButton = styled(Button)`
	padding: 10px;
	background: ${props => props.theme.palette.colors.basic[150]};
	min-width: 32px;
	color: ${props => props.theme.palette.colors.basic[750]};
	&:hover {
		background: hsl(240, 6%, 97%);
	}
`;

export interface HighlightedNode {
	highlighted: boolean;
	text: string;
}

interface HotspotHighlightInputProps {
	value: HighlightedNode[];
	// eslint-disable-next-line no-unused-vars
	onChange: (value: HighlightedNode[]) => void;
	readonly?: boolean;
	student?: boolean;
	currentIndex?: number;
}

const HotspotHighlightInput = ({
	value,
	onChange,
	readonly,
	student,
	currentIndex = 0
}: HotspotHighlightInputProps) => {
	const classes = useStyles({ student: student as boolean });

	const contentRef = useRef<HTMLDivElement>(null);
	const [position, setPosition] = useState<Range | undefined>();

	const fixContent = (container, level = 0) => {
		container.childNodes.forEach(node => {
			const newText = document.createTextNode(node.innerText);
			if (level >= 3) container.replaceChild(newText, node);
			else if (level === 2 && node.nodeName !== "#text") container.replaceChild(newText, node);
			else if (level === 1 && node.className !== "remove" && node.nodeName !== "#text") {
				node.querySelector(".remove")?.remove();
				container.replaceChild(newText, node);
			} else if (level === 0 && node.className !== "highlight" && node.nodeName !== "#text") {
				container.replaceChild(newText, node);
			}

			if (node.nodeName !== "#text") {
				fixContent(node, level + 1);
			}
		});
	};

	const handleChange = container => {
		const newValue: HighlightedNode[] = [];
		container.childNodes.forEach(node => {
			const highlighted = node.nodeName !== "#text";

			newValue.push({
				highlighted,
				text: highlighted ? node.childNodes[0].textContent : node.textContent
			});
		});

		onChange(
			newValue
				.filter(({ text }) => text !== "")
				.reduce((acc: HighlightedNode[], item: HighlightedNode) => {
					if (acc.length === 0) return [item];

					const lastAccItem = acc[acc.length - 1];

					if (lastAccItem.highlighted === item.highlighted) {
						lastAccItem.text += item.text;
					} else {
						acc.push(item);
					}
					return acc;
				}, [])
		);
	};

	const handleSelect = () => {
		const fragment = position?.extractContents();

		if (!fragment || fragment.parentElement?.className === "highlight") return;

		position?.deleteContents();
		fragment.childNodes.forEach(node => {
			const wrapper = document.createElement("span");
			wrapper.className = "highlight";
			wrapper.innerHTML = node.textContent + `<span class="remove" id="${currentIndex}">x</span>`;
			node.replaceWith(wrapper);
		});
		position?.insertNode(fragment);
		fixContent(contentRef.current);
		handleChange(contentRef.current);
	};

	const removeHandler = useCallback(e => {
		const target = e.target as HTMLSpanElement;
		if (target.className === "remove" && target?.id == currentIndex?.toString()) {
			target.parentElement?.replaceWith(target.parentElement.firstChild ?? "");
			handleChange(contentRef.current);
		}
	}, []);

	const keyPressHandler = useCallback(e => {
		const selection = window.getSelection();
		const parent = selection?.getRangeAt(0).startContainer.parentElement;
		if (
			parent?.className === "remove" &&
			contentRef.current &&
			!["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.code)
		) {
			e.preventDefault();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("click", removeHandler);
		document.addEventListener("keydown", keyPressHandler);

		return () => {
			document.removeEventListener("click", removeHandler);
			document.removeEventListener("keydown", keyPressHandler);
		};
	}, []);

	const handlePosition = newPosition => {
		setPosition(newPosition);
	};

	const html = value
		.map(({ highlighted, text }) =>
			highlighted ? `<span class="highlight">${text}<span class="remove" id="${currentIndex}">x</span></span>` : text
		)
		.join(" ");
	return (
		<>
			<StyledContentEditable
				className={classes.editable}
				innerRef={contentRef}
				disabled={readonly}
				html={html}
				onChange={() => {
					fixContent(contentRef.current);
					handleChange(contentRef.current);
				}}
				onBlur={() => {
					const selection = window.getSelection && window.getSelection();
					if (selection && selection.rangeCount > 0) {
						handlePosition(selection.getRangeAt(0));
					}
				}}
			/>
			<Box display="flex" justifyContent="flex-end" mt={1}>
				{student ? (
					<StyledMatButton variant={"contained"} onClick={handleSelect}>
						Highlight
					</StyledMatButton>
				) : (
					<TransparentButton onClick={handleSelect}>
						<img alt="hotspot icon" src={HotspotHighlightSVG} />
					</TransparentButton>
				)}
			</Box>
		</>
	);
};

export default HotspotHighlightInput;
