import styled from "styled-components";

export const BannerContainer = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 4px solid #ffd043;
	min-height: 96px;

	svg {
		min-width: 40px;
	}

	padding: ${({ $isMobile }) => ($isMobile ? "1rem 2rem" : 0)};
`;

export const TextBox = styled.div`
	margin-left: 2rem;
`;

export const Text = styled.div`
	color: #ffffff;
	font-family: var(--poppins);
	font-weight: bold;
	font-size: ${({ fs }) => (fs ? fs : 0.8)}rem;
`;
