import { BaseModel } from "./base.model";
import { LocationPackageTypeModel } from "./locationPackageType.model";

export interface LocationPackage extends BaseModel {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	name: string;
	locationPackageTypeId: number;
	locationPackageType: LocationPackageTypeModel;
	courseId: number;
	isActive: boolean;
	isDeleted: boolean;
	description: string[];
	allowedUST: {
		id: number;
		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
		nextSubscriptionTypeId: number;
		name: string;
		description: string;
		isActive: boolean;
		isTrial: boolean;
		isRecurring: boolean;
		duration: {
			days: number;
			months: number;
		};
		userSubscriptionTypeCategoryId: number;
		subTypeEIDItems: {
			id: number;
			createdAt: string;
			updatedAt: string;
			deletedAt: string | null;
			integrationId: number;
			subscriptionTypeId: number;
			parentId: string | null;
			data: {
				name: string;
				price: number;
				planId: string;
				currency: string;
				planName: string;
			};
			mainImageKey: string | null;
			isForBook: boolean;
			mainImageUrl: string;
		}[];
	};
}

export interface SubTypeEIDItems {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	nextSubscriptionTypeId: number;
	name: string;
	description: string;
	isActive: true;
	isTrial: boolean;
	isRecurring: boolean;
	duration: {
		days: number;
		months: number;
	};
	userSubscriptionTypeCategoryId: number;
	subTypeEIDItems: {
		id: number;
		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
		integrationId: number;
		subscriptionTypeId: number;
		parentId: string | null;
		data: {
			name: string;
			price: number;
			planId: string;
			currency: string;
			planName: string;
		};
		mainImageKey: string | null;
		isForBook: boolean;
		mainImageUrl: string;
	}[];
}

export enum Integrations {
	StripeIntegrationId = 1, // eslint-disable-line no-unused-vars
	AcutrackIntegrationId = 2, // eslint-disable-line no-unused-vars
	DigitalAssetIntegrationId = 3, // eslint-disable-line no-unused-vars
	PrintBindShipIntegrationId = 4 // eslint-disable-line no-unused-vars
}
